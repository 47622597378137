import React, { FC } from 'react';
import { ChevronLink, IconCheck, Spacer, Text } from 'dss-ui-library';
import { Link } from '@ncs-frontend-monorepo/content-library';
import styles from './NetTVDescription.module.scss';
import planStyles from '../PlanElements.module.scss';
import { Product, TV_CONTENT } from '@ncs-frontend-monorepo/order';

interface DetailDescriptionProps {
  plan: Product;
  moreInformationLink: () => void;
  channelLink: () => void;
  hasChannelList: boolean;
  disabled?: boolean;
}

export const DetailDescription: FC<DetailDescriptionProps> = ({
  moreInformationLink,
  channelLink,
  hasChannelList = false,
  disabled = false,
}) => {
  const contentTV = TV_CONTENT['tv-internet'];

  return (
    <>
      <Text appearance="t5">
        <ul className={styles.list}>
          {contentTV?.list?.map((item, ix) => (
            <li className={styles.listItem} key={ix + item}>
              <IconCheck
                width={24}
                height={24}
                color="blue"
                className={styles.listIcon}
              />
              <div className={styles.listItemText}>{item}</div>
            </li>
          ))}
        </ul>
      </Text>
      <Spacer y={1} block>
        {hasChannelList && (
          <>
            <ChevronLink
              e2e="channelList-link"
              color="blue"
              onClick={channelLink}
              appearance="t5"
            >
              Zur Senderliste
            </ChevronLink>
            <Spacer b={1} block />
          </>
        )}
      </Spacer>
      <Spacer b={1}>
        <Text appearance="t5" block>
          Buche optional die NetTV-Box für 2,00 € mtl. hinzu.
        </Text>
      </Spacer>
      <Link
        e2e="nettv-information-link"
        url="#nettv-information-link"
        onClick={moreInformationLink}
      >
        <Text
          appearance="t5"
          className={disabled ? planStyles.isDisabled : undefined}
        >
          Mehr Informationen
        </Text>
      </Link>
    </>
  );
};

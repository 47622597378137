import cx from 'classnames';
import { Grid, Separator, Spacer, Text } from 'dss-ui-library';
import { TextLink } from '@ncs-frontend-monorepo/content-library';
import NextImage from 'next/image';
import React from 'react';
import { useModalContext } from '../../ModalContext';
import styles from './ChannelList.module.scss';
import {
  CHANNEL_PATH,
  ProductId,
  TV_CHANNEL_LIST,
  TV_CHANNEL_LIST_COMPLETE,
} from '@ncs-frontend-monorepo/order';
import { getEnv } from '@ncs-frontend-monorepo/utils';

interface ChannelListProps {
  templateId: ProductId;
  visibleItems?: 6 | 8;
  headline?: string;
  embedded?: boolean;
}

export const ChannelList: React.FC<ChannelListProps> = ({
  templateId,
  visibleItems = 6,
  headline,
  embedded = false,
}) => {
  const { handleModal } = useModalContext();

  const handleClick = () => {
    handleModal({
      content: (
        <>
          <Text appearance="t4_2" color="blue">
            Senderliste
          </Text>
          <Spacer t={1} b={3} block>
            <Separator height={4} color="superlight-grey" />
          </Spacer>

          <Grid.Row>
            {TV_CHANNEL_LIST_COMPLETE?.[templateId]?.map((channelName, key) => (
              <Grid.Column xs={4} s={3} m={2} key={`${channelName}-${key}`}>
                <div className={styles.modalChannelImage}>
                  <NextImage
                    src={`${
                      getEnv().API_FILE_URL
                    }${CHANNEL_PATH}/${channelName}.png`}
                    alt={channelName}
                    fill
                    style={{
                      objectFit: 'contain',
                    }}
                  />
                </div>
              </Grid.Column>
            ))}
          </Grid.Row>
        </>
      ),
    });
  };
  return (
    <>
      {headline && (
        <>
          <Text appearance="t4_2" color="blue" />
          <Spacer t={1} b={3} block>
            <Separator height={4} color="superlight-grey" />
          </Spacer>
          <Text appearance="t2_2">{headline}</Text>
        </>
      )}
      <Spacer t={1} b={2} block>
        <Text appearance="t5">
          <Grid.Row collapsed={embedded}>
            {TV_CHANNEL_LIST?.[templateId]
              ?.slice(0, visibleItems)
              ?.map((channelName, key) => (
                <Grid.Column
                  xs={visibleItems === 6 ? 4 : 3}
                  className={cx(styles.channelImage, {
                    [styles.channelImageEmbedded]: embedded,
                  })}
                  key={`${channelName}-${key}`}
                >
                  <NextImage
                    src={`${
                      getEnv().API_FILE_URL
                    }${CHANNEL_PATH}/${channelName}.png`}
                    alt={channelName}
                    fill
                    style={{
                      objectFit: 'contain',
                    }}
                  />
                </Grid.Column>
              ))}
          </Grid.Row>
        </Text>
        {!embedded && (
          <TextLink
            url={null}
            onClick={handleClick}
            appearance="t5"
            e2e="show-all-channels"
          >
            Alle Sender anzeigen
          </TextLink>
        )}
      </Spacer>
    </>
  );
};

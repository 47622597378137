import React, { FC } from 'react';
import {
  Expandable,
  IconChevron,
  IconPhone,
  Spacer,
  Text,
  Toggle,
  Footnote,
} from 'dss-ui-library';
import styles from '../PlanElements.module.scss';
import cx from 'classnames';
import { ProductInfoDetails, useProductContext } from '../../ProductContext';
import {
  Product,
  Telephony,
  Footnote as IFootnote,
} from '@ncs-frontend-monorepo/order';

interface TelephonyDescriptionProps {
  plan: Product;
  telephonyFootnote?: IFootnote['footnote'];
  onToggle?: () => void;
  checked?: boolean;
  expandable?: boolean;
  disabled?: boolean;
}

export const TelephonyDescription: FC<TelephonyDescriptionProps> = ({
  plan,
  telephonyFootnote,
  onToggle,
  checked,
  expandable = false,
  disabled = false,
}) => {
  const { toggledDetail, toggleInfo } = useProductContext();
  const open = toggledDetail.includes(ProductInfoDetails.phone);

  const description = (
    <Text appearance="t5">
      Telefonieren so viel du möchtest. <br />
      Mit der Telefon-Flat telefonierst du jederzeit kostenfrei ins gesamte{' '}
      {plan.telephony === Telephony.NATIONAL_FLAT && 'deutsche '}
      {plan.telephony === Telephony.INTERNATIONAL_FLAT_1 && 'internationale '}
      Festnetz.
    </Text>
  );
  return (
    <>
      <div className={styles.description}>
        <div className={styles.descriptionHeadline}>
          {expandable && (
            <>
              <IconPhone
                color="blue"
                width={25}
                height={25}
                className={disabled ? styles.isDisabled : undefined}
              />
              <Spacer r={1} />
            </>
          )}
          <Text appearance="t5_2">
            Telefon-Flat{' '}
            {telephonyFootnote && (
              <Footnote number={telephonyFootnote.number} />
            )}
          </Text>
        </div>
        <div className={styles.toggles}>
          {onToggle && (
            <Toggle
              label=""
              type="checkbox"
              name={`toggle-phone-${plan.id}`}
              e2e="toggle-phone"
              checked={checked}
              onChange={onToggle}
            />
          )}

          {expandable && (
            <IconChevron
              small
              color="blue"
              width={24}
              height={24}
              onClick={() => {
                toggleInfo(ProductInfoDetails.phone);
              }}
              className={cx(
                styles.toggleIcon,
                open && styles.open,
                disabled && styles.isDisabled,
              )}
            />
          )}
        </div>
      </div>
      {expandable ? (
        <Expandable e2e="telephony-description" expanded={open}>
          <Spacer t={1} l={3} block>
            {description}
          </Spacer>
        </Expandable>
      ) : (
        <Spacer t={1} block>
          {description}
        </Spacer>
      )}
    </>
  );
};

import cx from 'classnames';
import {
  Expandable,
  Footnote,
  IconChevron,
  IconNetTV,
  Spacer,
  Text,
  Toggle,
} from 'dss-ui-library';
import dynamic from 'next/dynamic';
import React, { FC, useEffect, useState } from 'react';
import { getTVShortDescription } from '../../../utils';
import { useModalContext } from '../../ModalContext';
import { ProductInfoDetails, useProductContext } from '../../ProductContext';
import planStyles from '../PlanElements.module.scss';
import { PlanDetailDescription } from './PlanDetailDescription';
import { DetailDescription } from './DetailDescription';
import {
  CABLE_TV_COMBI_GROUPS,
  Product,
  Footnote as IFootnote,
} from '@ncs-frontend-monorepo/order';
import { useScrollTo } from '@ncs-frontend-monorepo/utils';

const NetTvModal = dynamic(
  () => import('../../Modals/NetTvModal').then(({ NetTvModal }) => NetTvModal),
  {
    ssr: false,
  },
);

interface NetTVDescriptionProps {
  plan: Product;
  netTvFootnote?: IFootnote['footnote'];
  onToggle?: () => void;
  checked?: boolean;
  expandable?: boolean;
  disabled?: boolean;
  hasDetailedDescription?: boolean;
}

export const NetTVDescription: FC<NetTVDescriptionProps> = ({
  plan,
  netTvFootnote,
  onToggle,
  checked,
  expandable = false,
  disabled = false,
  hasDetailedDescription = false,
}) => {
  const { handleModal } = useModalContext();
  const { toggledDetail, toggleInfo } = useProductContext();
  const [hasChannelList, setHasChannelList] = useState(false);
  const open = toggledDetail.includes(ProductInfoDetails.tv);
  const scrollToChannelList = useScrollTo('grid-channels');

  const handleClick = () => {
    handleModal({
      content: <NetTvModal tvType={plan.tv.type} />,
      e2e: `nettv-app-modal`,
    });
  };

  useEffect(() => {
    const channelList = document.querySelector('[data-e2e="grid-channels"]');
    if (channelList !== null) {
      setHasChannelList(true);
    }
  }, []);

  return (
    <>
      <div className={planStyles.description}>
        <div className={planStyles.descriptionHeadline}>
          {expandable && (
            <>
              <IconNetTV
                color="blue"
                width={25}
                height={25}
                className={disabled && planStyles.isDisabled}
              />
              <Spacer r={1} />
            </>
          )}

          <Text appearance="t5_2">
            {getTVShortDescription(plan)}{' '}
            {netTvFootnote && <Footnote number={netTvFootnote.number} />}
          </Text>
        </div>
        <div className={planStyles.toggles}>
          {onToggle && !CABLE_TV_COMBI_GROUPS.includes(plan.group) && (
            <Toggle
              label=""
              type="checkbox"
              name={`toggle-nettv-${plan.id}`}
              e2e="toggle-nettv"
              checked={checked}
              onChange={onToggle}
            />
          )}
          {expandable && (
            <IconChevron
              small
              color="blue"
              width={24}
              height={24}
              onClick={() => {
                toggleInfo(ProductInfoDetails.tv);
              }}
              className={cx(
                planStyles.toggleIcon,
                open && planStyles.open,
                disabled && planStyles.isDisabled,
              )}
            />
          )}
        </div>
      </div>
      {expandable ? (
        <Expandable e2e="nettv-description" expanded={open}>
          <Spacer y={1} l={3} block>
            {!hasDetailedDescription ? (
              <PlanDetailDescription
                plan={plan}
                moreInformationLink={handleClick}
                withImage
              />
            ) : (
              <DetailDescription
                plan={plan}
                moreInformationLink={handleClick}
                channelLink={scrollToChannelList}
                hasChannelList={hasChannelList}
              />
            )}
          </Spacer>
        </Expandable>
      ) : (
        <Spacer y={1}>
          {!hasDetailedDescription ? (
            <PlanDetailDescription
              plan={plan}
              moreInformationLink={handleClick}
            />
          ) : (
            <DetailDescription
              plan={plan}
              moreInformationLink={handleClick}
              channelLink={scrollToChannelList}
              hasChannelList={hasChannelList}
            />
          )}
        </Spacer>
      )}
    </>
  );
};

import React, { FC } from 'react';
import { Spacer, Text } from 'dss-ui-library';
import { Link } from '@ncs-frontend-monorepo/content-library';
import NextImage from 'next/image';
import { ChannelList } from '../ChannelList';
import styles from './NetTVDescription.module.scss';
import planStyles from '../PlanElements.module.scss';
import nettvbox from '../NetTVBox/NetTV-Box_202111.png';
import {
  ProductId,
  Product,
  TV_PLAN_DESCRIPTION,
  FTTH_FACTORY_GROUPS,
} from '@ncs-frontend-monorepo/order';
import { NetTvType } from '@ncs-frontend-monorepo/availability';

interface PlanDetailDescriptionProps {
  plan: Product;
  withImage?: boolean;
  moreInformationLink: () => void;
  disabled?: boolean;
}

export const PlanDetailDescription: FC<PlanDetailDescriptionProps> = ({
  plan,
  withImage = false,
  moreInformationLink,
  disabled = false,
}) => {
  const showChannelList = plan.tv?.type === NetTvType.NET_TV_CABLE && withImage;
  const showBox =
    !FTTH_FACTORY_GROUPS.includes(plan.group) &&
    plan.tv?.type === NetTvType.NET_TV_INTERNET &&
    withImage;

  return (
    <>
      <Text appearance="t5">{TV_PLAN_DESCRIPTION[plan.tv.type] || ''}</Text>
      {showBox && (
        <Spacer t={2} block>
          <div className={styles.netTV}>
            <Text appearance="t5">Optional: NetTV-Box</Text>
            <div className={styles.netTVBox}>
              <NextImage
                src={nettvbox}
                alt={NetTvType.NET_TV_INTERNET}
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </div>
          </div>
        </Spacer>
      )}
      {showChannelList && (
        <Spacer t={1} r={3} block>
          <ChannelList
            templateId={ProductId.TV_CABLE}
            visibleItems={6}
            embedded
          />
        </Spacer>
      )}
      <Spacer b={2} block />
      <Link
        e2e="nettv-information-link"
        url="#nettv-information-link"
        onClick={moreInformationLink}
      >
        <Text
          appearance="t5"
          className={disabled ? planStyles.isDisabled : undefined}
        >
          Mehr Informationen
        </Text>
      </Link>
    </>
  );
};
